import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import { GptUsageItem, useCommonStore, useUserStore } from '@/store';
import { useMemo } from 'react';
import './index.less';
import classNames from 'classnames';
import CustomIcon from '../CustomIcon';
import { basicChatModelName } from '@/common/model';
import { Trans, useTranslation } from 'react-i18next';

enum TypeEnum {
  DOC = 'doc',
  PPT = 'ppt',
  CHAT = 'chat',
  WRITING = 'writing',
}

interface UsageTipProps {
  type: 'doc' | 'ppt' | 'chat' | 'writing';
  model?: string;
  subType?: 'wordCount' | 'form' | 'download';
}

const UsageTip: React.FC<UsageTipProps> = (props) => {
  const { type, model, subType } = props;
  const { userInfo } = useUserStore();
  const { usageInfo } = useCommonStore();
  const { t } = useTranslation();

  const docAvailableQuota = useMemo(() => {
    const docUsage = usageInfo.doc as GptUsageItem;
    return Math.max(docUsage?.total - docUsage?.used, 0);
  }, [usageInfo]);

  //  doc upload 是否即将耗尽
  const isNearlyDocUploadLimit = useMemo(() => {
    return docAvailableQuota < 2;
  }, [docAvailableQuota]);

  const aiCreationAvailableQuota = useMemo(() => {
    const aiUsage = usageInfo.ai as GptUsageItem;
    return Math.max(aiUsage?.total - aiUsage?.used, 0);
  }, [usageInfo]);

  //  ai creation 是否即将耗尽
  const isNearlyAICreationLimit = useMemo(() => {
    return aiCreationAvailableQuota < 2;
  }, [aiCreationAvailableQuota]);

  //  standard 剩余使用量
  const chatAvailableQuota = useMemo(() => {
    const gpt3Usage = usageInfo.gpt3 as GptUsageItem;
    const gpt4Usage = usageInfo.gpt4 as GptUsageItem;
    return Math.max(
      model === basicChatModelName
        ? gpt3Usage?.total - gpt3Usage?.used
        : gpt4Usage?.total - gpt4Usage?.used,
      0,
    );
  }, [model, usageInfo]);

  //  standard/ GPT-4 是否即将耗尽
  const isNearlyChatLimit = useMemo(() => {
    return chatAvailableQuota === 1;
  }, [model, chatAvailableQuota]);

  const writingLongAvailableQuota = useMemo(() => {
    const writeLongUsage = usageInfo.writeLong as GptUsageItem;
    return writeLongUsage?.total && writeLongUsage?.total - writeLongUsage?.used > 0;
  }, [usageInfo]);

  const writingFromAvailableQuota = useMemo(() => {
    const writePaperReportUsage = usageInfo.writePaperReport as GptUsageItem;
    return (
      writePaperReportUsage?.total && writePaperReportUsage?.total - writePaperReportUsage?.used > 0
    );
  }, [usageInfo]);

  const writingDownloadAvailableQuota = useMemo(() => {
    const writeExtractUsage = usageInfo.writeExtract as GptUsageItem;
    return writeExtractUsage?.total && writeExtractUsage?.total - writeExtractUsage?.used > 0;
  }, [usageInfo]);

  const usageTipRender = () => {
    switch (type) {
      case TypeEnum.DOC:
        if (userInfo?.isUnlimited) {
          return (
            <>
              <UserBadge renderType="usageHint" />
              <span>{t('components.usageTip.unlimitedFiles')}</span>
            </>
          );
        } else {
          const plural = !isNearlyDocUploadLimit ? 's' : '';
          return (
            <div className="doc-hint">
              <div>
                <span
                  className={classNames('available-quota', 'num-base', {
                    zero: !docAvailableQuota,
                  })}
                >
                  {docAvailableQuota}
                </span>
                <span>{t('components.usageTip.files_today', { plural })}</span>
              </div>
              {isNearlyDocUploadLimit && (
                <div>
                  <span>{t('components.usageTip.upgrade')}</span>
                  <UserBadge renderType="usageHint" />
                </div>
              )}
            </div>
          );
        }
      case TypeEnum.PPT:
        if (userInfo?.isUnlimited) return null;
        else {
          return isNearlyAICreationLimit ? (
            <div className="ppt-hint">
              <div>
                <span
                  className={classNames('num-base', {
                    zero: !aiCreationAvailableQuota,
                  })}
                >
                  {aiCreationAvailableQuota}
                </span>
                <span>{t('components.usageTip.left')}</span>
              </div>
              <div>
                <span>{t('components.usageTip.upgrade')}</span>
                <UserBadge renderType="usageHint" />
              </div>
            </div>
          ) : (
            <div>
              <Trans
                i18nKey="components.usageTip.create_decks"
                components={{ 1: <span className="num-base" /> }}
                values={{ quota: aiCreationAvailableQuota }}
              />
            </div>
          );
        }
      case TypeEnum.CHAT:
        const modelName = model === basicChatModelName ? basicChatModelName : 'GPT-4/4o';
        return (
          isNearlyChatLimit && (
            <div className="chat">
              <CustomIcon type="ExclamationMark" />
              <span
                className={classNames('usage-num', {
                  zero: !chatAvailableQuota,
                })}
              >
                {chatAvailableQuota}
              </span>
              <span className="desc">
                {t('components.usageTip.query_left_today', { modelName, escapeValue: false })}
              </span>
              <div className="upgrade-consumption">
                <span>{t('components.usageTip.upgrade')}</span>
                <UserBadge renderType="usageHint" />
              </div>
            </div>
          )
        );
      case TypeEnum.WRITING:
        let availableQuota = null;

        if (userInfo?.isUnlimited) return <UserBadge renderType="usageHint" />;

        if (subType === 'wordCount') {
          availableQuota = writingLongAvailableQuota;
        } else if (subType === 'form') {
          availableQuota = writingFromAvailableQuota;
        } else if (subType === 'download') {
          availableQuota = writingDownloadAvailableQuota;
        }

        return availableQuota ? null : <UserBadge renderType="usageHint" />;
    }

    return null;
  };

  return <div className="usage-tip">{usageTipRender()}</div>;
};

export default UsageTip;
