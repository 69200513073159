import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { EChatType, SendControllerViewProps } from '../types';
import { useCommonStore, useEnhanceUploadStore, useSendStore, useSendUploadStore } from '@/store';
import { PromptTemplate } from '@/type';
import {
  DEFAULT_MORE_TPL,
  DEFAULT_RECENT_TPL,
  DEFAULT_WRITING_TPL,
  SEARCH_TEMPLATE_KEYWORDS_AT,
} from '../constants';
import { useTranslation } from 'react-i18next';
import { useGASendEvent, useDA } from '@/hooks';
import { useNavigate } from 'react-router-dom';

/**
 * 新输入框模版相关的hook
 * @param props
 * @param refs
 * @returns
 */
const useSendTemplate = (
  props: Pick<
    SendControllerViewProps,
    'channelId' | 'template' | 'onTemplateClear' | 'chatType' | 'openTplPreview'
  >,
  refs?: {
    userPromptRef?: RefObject<{
      deactive: () => void;
    }>;
  },
) => {
  // states
  const [previewKey, setPreviewKey] = useState('');
  const [searchTplShow, setSearchTplShow] = useState(false);

  // store
  const { imageUrls, isUploadImage, allUploadFiles } = useSendUploadStore((state) => ({
    imageUrls: state.imageUrls,
    allUploadFiles: state.allUploadFiles,
    isUploadImage: state.isUploadImage,
  }));
  const { reset } = useEnhanceUploadStore((state) => ({
    reset: state.reset,
  }));
  const { pageLanguage } = useCommonStore((state) => ({
    pageLanguage: state.pageLanguage,
  }));
  const { value, innerTpl, setValue, setInnerTpl } = useSendStore((state) => ({
    value: state.value,
    innerTpl: state.innerTpl,
    setValue: state.setValue,
    setInnerTpl: state.setInnerTpl,
  }));

  // hooks
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const navigate = useNavigate();

  /**AT按钮是否展示, 在对话页内不展示或者命中灰度不展示 */
  const showAddTpl = useMemo(() => {
    return !props?.channelId;
  }, [props?.channelId]);

  const defalutPPTTpl = useMemo(() => {
    const lng = ['en', 'ar'].includes(pageLanguage) ? pageLanguage : 'en';
    const tpl = {
      ...DEFAULT_RECENT_TPL[0],
      id: DEFAULT_RECENT_TPL[0].id,
      promptTemplateId: DEFAULT_RECENT_TPL[0].promptTemplateId,
      name: t(DEFAULT_RECENT_TPL[0].nameKey),
      teaser: t(DEFAULT_RECENT_TPL[0].teaserKey),
      thumbnail: DEFAULT_RECENT_TPL[0].thumbnail,
      mediaType: 'ppt',
      shareKey: props?.channelId ? '' : JSON.parse(DEFAULT_RECENT_TPL[0].shareKeyI18n)[`${lng}`],
      promptHint: t(DEFAULT_RECENT_TPL[0].promptHintKey),
      infoCollect: false,
    };
    return tpl;
  }, [pageLanguage, props?.channelId, t]);

  /**是否展示搜索模版结果 */
  const showSearchTplRes = useMemo(() => {
    // 没有内部、外部模版且没有上传图片
    if (!props?.template?.id && !innerTpl?.id && searchTplShow && imageUrls.length === 0)
      return true;
    return false;
  }, [props?.template?.id, innerTpl?.id, searchTplShow, imageUrls.length]);

  /**真实要展示的模版 */
  const realTemplate = useMemo(() => {
    if (props?.template?.id) return props?.template;
    if (innerTpl?.id) return innerTpl;
    return {} as PromptTemplate;
  }, [props?.template, innerTpl]);

  /**
   * 模板缩略图是否展示
   * 命中灰度时，非默认PPT模版不展示缩略图，其他情况均展示
   */
  const showTplOverview = useMemo(() => {
    return realTemplate?.id !== DEFAULT_RECENT_TPL[0].id;
  }, [realTemplate?.id]);

  /**
   * 是否展示关闭默认ppt模版按钮，
   * 只有在首页并且是默认模版时
   */
  const showCloseDefaultPPTTpl = useMemo(() => {
    return (
      props?.chatType === EChatType.normal &&
      realTemplate?.id === DEFAULT_RECENT_TPL[0].id &&
      !showTplOverview
    );
  }, [props?.chatType, realTemplate?.id, showTplOverview]);

  /**
   * 是否需要反向排列模版预览容器内部反转容器内视图的顺序
   * 当模版是ppt且需要展示预览缩略图时，反转容器内视图的顺序
   */
  const isReverseTPLBox = useMemo(() => {
    return showTplOverview && realTemplate?.mediaType === 'ppt';
  }, [realTemplate?.mediaType, showTplOverview]);

  /**选择模版时的列表数据 */
  const tplList = useMemo(() => {
    return [defalutPPTTpl, DEFAULT_WRITING_TPL, DEFAULT_MORE_TPL];
  }, [defalutPPTTpl]);

  // 选择模版AT按钮是否可用
  const canAddTpl = useMemo(() => {
    // 有图片、有模版、有输入框上传文件（allUploadFiles）时，均不可用
    if (
      props?.template?.id ||
      innerTpl?.id ||
      imageUrls.length > 0 ||
      allUploadFiles.length > 0 ||
      isUploadImage
    )
      return false;
    return true;
  }, [props?.template?.id, innerTpl?.id, imageUrls.length, allUploadFiles.length, isUploadImage]);

  const tplTooltip = useMemo(() => {
    return t('pages.home.tool');
  }, [t]);

  const handleTemplatePreview = useCallback(() => {
    if (props?.channelId) return;
    realTemplate?.shareKey && setPreviewKey(realTemplate?.shareKey);
    refs?.userPromptRef?.current?.deactive();
  }, [props?.channelId, realTemplate?.shareKey, refs?.userPromptRef]);

  const handleSearchTpl = useCallback(
    (value: string) => {
      if (value === SEARCH_TEMPLATE_KEYWORDS_AT && props?.chatType !== EChatType.writing) {
        return setSearchTplShow(true);
      }
      if (!value) {
        return setSearchTplShow(false);
      }
    },
    [props?.chatType],
  );

  const handleAddTpl = () => {
    refs?.userPromptRef?.current?.deactive();
    sendEvent('Click_At');
    if (!canAddTpl) return false;
    if (!searchTplShow) {
      handleSearchTpl(SEARCH_TEMPLATE_KEYWORDS_AT);
    }
    setSearchTplShow(!searchTplShow);
  };

  const handleSelectTpl = useCallback(
    (tpl: PromptTemplate) => {
      sendEvent('Click_Templates', { Template_Name: tpl?.name });
      sendDAEvent('ChooseATemplate_Template_Click', { template_id: tpl?.id });
      switch (tpl.id) {
        // ppt
        case 20000:
          setInnerTpl(tpl);
          break;
        // writing
        case 120013:
          navigate('/writing');
          break;
        default:
          navigate('/creation/All/Flowchart');
          break;
      }

      if (value === SEARCH_TEMPLATE_KEYWORDS_AT) {
        setValue('');
      }
    },
    [navigate, sendDAEvent, sendEvent, setInnerTpl, setValue, value],
  );

  const handleCloseTemplateMode = useCallback(() => {
    props?.onTemplateClear?.();
    setInnerTpl({});
    setPreviewKey('');
    setSearchTplShow(false);
    setValue('');
    // 清空enhanceppt 上传的文件
    reset();
  }, [props, reset, setInnerTpl, setValue]);

  useEffect(() => {
    if (props?.chatType === EChatType.chatWithPPT && !props?.template?.id) {
      setInnerTpl(defalutPPTTpl);
    }
  }, [defalutPPTTpl, props?.chatType, props?.template?.id, setInnerTpl]);

  useEffect(() => {
    console.log(111, props?.openTplPreview);
    if (
      props?.chatType === EChatType.chatWithTPL &&
      props?.openTplPreview === true &&
      realTemplate?.shareKey
    ) {
      setPreviewKey(realTemplate?.shareKey);
    } else {
      setPreviewKey('');
    }
  }, [props?.chatType, props?.openTplPreview, realTemplate?.shareKey]);

  return {
    previewKey,
    showAddTpl,
    showSearchTplRes,
    realTemplate,
    showTplOverview,
    showCloseDefaultPPTTpl,
    tplList,
    searchTplShow,
    isReverseTPLBox,
    canAddTpl,
    tplTooltip,
    setSearchTplShow,
    setPreviewKey,
    handleTemplatePreview,
    handleAddTpl,
    handleSelectTpl,
    handleCloseTemplateMode,
    handleSearchTpl,
  };
};

export default useSendTemplate;
