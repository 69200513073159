import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { message } from 'antd';
import {
  DEFAULT_IMAGE_MESSAGE,
  MAX_COUNT,
  SEARCH_TEMPLATE_KEYWORDS_AT,
  UPGRADE_DEFAULT_LANG,
} from '../constants';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { EChatType, OneChatFileResponse, SendControllerViewProps, SendParams } from '../types';
import {
  useChatStore,
  useCommonStore,
  useEnhanceUploadStore,
  useSendStore,
  useSendUploadStore,
  useUserStore,
  useWriteStore,
} from '@/store';
import { useDA } from '@/hooks';
import { useGlobalModal } from '@/layout/BasicLayout';
import useInputTracker from '@/components/InputControl/hooks/useInputTracker';
import { ImageUploadRef } from '@/components/ImageUpload';
import { PromptTemplate, UpsertDocResult, ResponseType, CreateChatParams } from '@/type';
import { throttle, trim } from 'lodash-es';
import { UPLOAD_STATUS } from '@/store/sendUpload';
import { PPTControllerRef } from '@/components/PPTController';
import { chatCreateUsingPOST, upsertWritingDoc } from '@/services';
import { WRITE_TEMPLATE_ID } from '@/common/config';
import { WritingControllerRef } from '@/components/WritingController';
import { FileType, getFileType } from '@/common/helpers/fileHelper';
import { fieldReplace } from '../helper';
import { basicChatModelName } from '@/common/model';

/**
 * 输入框输入操作相关的hook
 */
const useSendInput = (
  props: Pick<
    SendControllerViewProps,
    | 'placeholder'
    | 'channelId'
    | 'chatType'
    | 'stopShow'
    | 'processing'
    | 'selectedImageProgress'
    | 'selectedImage'
    | 'onSend'
  > & {
    isReparseFinish?: boolean;
    realTemplate: PromptTemplate;
    canAddImg?: boolean;
    switchChecked?: boolean;
    canAddTpl?: boolean;
    currentModel?: string;
    handleCloseTemplateMode?: () => void;
    handleSearchTpl?: (value: string) => void;
  },
  refs: {
    imageUploadRef?: MutableRefObject<ImageUploadRef | null>;
    pptControllerRef?: MutableRefObject<PPTControllerRef | null>;
    writingControllerRef?: MutableRefObject<WritingControllerRef | null>;
  },
) => {
  // state
  const [inputActive, setInputActive] = useState(false);
  const [isComposition, setIsComposition] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const { currentModel = basicChatModelName } = props;

  // store
  const { fileExtraInfo, file, uploadStatus, enhanceMd5, reset } = useEnhanceUploadStore(
    (state) => ({
      fileExtraInfo: state.fileExtraInfo,
      file: state.file,
      uploadStatus: state.uploadStatus,
      enhanceMd5: state.md5,
      reset: state.reset,
    }),
  );
  const {
    imageUrls,
    allUploadFiles,
    isUploadImage,
    isAllUploaded,
    imageFileNames,
    setImageUrls,
    setIsUploadImage,
    onReset,
  } = useSendUploadStore((state) => ({
    imageUrls: state.imageUrls,
    allUploadFiles: state.allUploadFiles,
    isUploadImage: state.isUploadImage,
    isAllUploaded: state.isAllUploaded,
    imageFileNames: state.imageFileNames,
    setImageUrls: state.setImageUrls,
    setIsUploadImage: state.setIsUploadImage,
    onReset: state.onReset,
  }));
  const { chatModel } = useCommonStore((state) => ({
    chatModel: state.chatModel,
  }));
  const { setDocTitle, setDocInitContent, setChannelId } = useWriteStore((state) => ({
    setDocTitle: state.setDocTitle,
    setDocInitContent: state.setDocInitContent,
    setChannelId: state.setChannelId,
  }));
  const { sendOcrLanguage, setChannel, setLastPPTTemplateId } = useChatStore((state) => ({
    sendOcrLanguage: state.sendOcrLanguage,
    setChannel: state.setChannel,
    setLastPPTTemplateId: state.setLastPPTTemplateId,
  }));
  const { value, setValue } = useSendStore((state) => ({
    value: state.value,
    setValue: state.setValue,
  }));

  const { isLogin } = useUserStore((state) => ({
    isLogin: state.userInfo.isLogin,
  }));

  // refs
  // 是否开始输入
  const isBeginTyping = useRef(true);

  // hooks
  const { t } = useTranslation();
  const matchPPT = useMatch('/ppt');
  const { sendDAEvent, preparePropertyFor } = useDA();
  const { trackInput, trackPaste, trackSend } = useInputTracker(value);
  const navigate = useNavigate();

  // layout
  const { checkLoginStatus } = useGlobalModal();

  const maximum = useMemo(() => {
    return value?.length > Math.floor(MAX_COUNT * 0.9);
  }, [value?.length]);

  const inputExceeded = useMemo(() => {
    return value?.length > MAX_COUNT;
  }, [value?.length]);

  // 输入框placeholder
  const computedPlaceholder = useMemo(() => {
    if (props?.realTemplate?.id) {
      // ppt 首页不使用本地默认模版的 placeholder
      if (matchPPT && props?.realTemplate.promptHintKey) return '';
      if (props?.realTemplate?.mediaType !== 'ppt') return props?.realTemplate.promptHint;
      if (props?.realTemplate?.mediaType === 'ppt')
        return file && fileExtraInfo?.topic
          ? `${fileExtraInfo?.topic} ${t('components.send.enhance')}`
          : t('components.send.enhance');
      return props?.realTemplate.promptHint;
    }
    if (allUploadFiles.length > 0 || imageUrls.length > 0 || props?.channelId)
      return t('components.send.send');
    if (props.placeholder) return props.placeholder;
    return t('components.send.placeholder');
  }, [
    allUploadFiles.length,
    file,
    fileExtraInfo?.topic,
    imageUrls.length,
    matchPPT,
    props?.channelId,
    props.placeholder,
    props?.realTemplate?.id,
    props?.realTemplate?.mediaType,
    props?.realTemplate.promptHint,
    props?.realTemplate.promptHintKey,
    t,
  ]);

  /**
   * 当轮对话的模式
   */
  const currentInputMode = useMemo(() => {
    if (props?.chatType) return props?.chatType;
    // ppt对话
    if (props?.realTemplate?.mediaType === 'ppt') return EChatType.chatWithPPT;
    // 其余都是普通on chat
    return EChatType.normal;
  }, [props?.chatType, props?.realTemplate]);

  // 是否可以发送
  const canSend = useMemo(() => {
    // one chat中
    if (props?.stopShow || props?.processing || props.isReparseFinish === false) return false;
    if (currentInputMode === EChatType.normal) {
      if (inputExceeded) return false;
      // 有图或有文档或有value即可发
      if (isUploadImage || props?.selectedImageProgress) return false;
      if (imageUrls.length > 0 || props?.selectedImage?.file_url) return true;
      // 上传单文件,不输入内容可发送
      if (allUploadFiles.length === 1) {
        return isAllUploaded;
      }
      // 多文件,必须输入
      if (allUploadFiles.length > 1) {
        return isAllUploaded && value.length > 0 && trim(value).length > 0;
      }
      return trim(value).length > 0;
    } else if (currentInputMode === EChatType.chatWithTPL) {
      // 通过模版创建对话
      if (props?.realTemplate?.connectionName === 'CHATIMAGE') {
        // 需要上传图片
        return (
          imageUrls.length > 0 ||
          props?.selectedImage?.file_url !== undefined ||
          trim(value).length > 0
        ); // 有图片或者就文字就可以发送
      }
      return trim(value).length > 0;
    } else if (currentInputMode === EChatType.writing) {
      return trim(value).length <= MAX_COUNT;
    } else {
      // one chat 创建ppt,高级配置是否处在请求阶段
      const isLoadingPPTConfig = refs?.pptControllerRef?.current?.getIsLoading();
      if (isLoadingPPTConfig === true) return false;

      // one chat 创建ppt有成功文件或者有value就能发
      if (file?.uid) return uploadStatus === UPLOAD_STATUS.Success;
      return trim(value).length > 0;
    }
  }, [
    props?.isReparseFinish,
    props?.stopShow,
    props?.processing,
    props?.selectedImageProgress,
    props?.selectedImage?.file_url,
    props?.realTemplate?.connectionName,
    currentInputMode,
    inputExceeded,
    isUploadImage,
    imageUrls.length,
    allUploadFiles.length,
    value,
    isAllUploaded,
    refs?.pptControllerRef,
    file?.uid,
    uploadStatus,
  ]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (isBeginTyping?.current && props.channelId) {
        // Chat_开始输入消息时上报
        sendDAEvent('Chat_BeginTypingMessage', {
          model: chatModel,
        });
        isBeginTyping.current = false;
      }
      if (!checkLoginStatus?.({ type: 'message' })) return;
      const value = event?.currentTarget?.value;
      setValue(value);
      trackInput(value);
      if (
        props?.canAddTpl &&
        (value?.startsWith(SEARCH_TEMPLATE_KEYWORDS_AT) || value.length === 0)
      ) {
        // 展示AT模版面板
        props?.handleSearchTpl?.(value);
        sendDAEvent('EnterAt_Exposure');
      }
    },
    [chatModel, checkLoginStatus, props, sendDAEvent, setValue, trackInput],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      if (!checkLoginStatus?.({ type: 'image' })) {
        return;
      }
      trackPaste(event);
      const items = event?.clipboardData?.items || [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.includes('image')) {
          if (imageUrls?.length > 0) {
            message.error(t('common.uploadImageError'));
            break;
          }
          const file = items[i].getAsFile();
          if (file && props?.canAddImg) {
            refs?.imageUploadRef?.current?.uploadMethod?.({ file });
            break;
          }
        }
      }
    },
    [checkLoginStatus, imageUrls?.length, props?.canAddImg, refs?.imageUploadRef, t, trackPaste],
  );

  const handleWritingDoc = useCallback(async () => {
    const res = await upsertWritingDoc<ResponseType<UpsertDocResult>>(
      {
        name: value,
        content: '',
        advanceConfig: refs?.writingControllerRef?.current?.getValues(),
      },
      {
        noAlertError: true,
      },
    );
    if (res.data?.channelId && res.data?.docId) {
      setChannelId(res.data?.channelId);
      setDocInitContent('');
      setDocTitle(value);

      localStorage.setItem(
        'writingState',
        JSON.stringify({
          ignoreGetDoc: true,
          automaticSend: true,
          message: value,
          model: currentModel,
          docTitle: value,
          channelId: res.data?.channelId,
          advanceConfig: refs?.writingControllerRef?.current?.getValues(),
          writingDocPromptTemplateId: WRITE_TEMPLATE_ID.WritingAdvance,
          searchSwitch: props?.switchChecked,
        }),
      );
      navigate(`/writing/${res.data?.docId}`, {
        state: {
          ignoreGetDoc: true,
          automaticSend: true,
        },
      });
    }
  }, [
    currentModel,
    navigate,
    props?.switchChecked,
    refs?.writingControllerRef,
    setChannelId,
    setDocInitContent,
    setDocTitle,
    value,
  ]);

  /**
   * 清空组件内部的状态和数据
   */
  const handleClearSendController = useCallback(() => {
    // 清空图片
    setImageUrls([]);
    setIsUploadImage(false);
    // 清空输入
    setValue('');
    // 清空上传文件
    onReset?.();
    // 清空enhance
    reset?.();
    // 清空模板
    props?.handleCloseTemplateMode?.();
    sendLoading && setSendLoading(false);
    isBeginTyping.current = true;
  }, [onReset, props, reset, sendLoading, setImageUrls, setIsUploadImage, setValue]);

  const createOneChatByFiles = useCallback(
    async <
      T extends {
        channel_id?: string;
        channel_name?: string;
      },
    >(
      params: CreateChatParams,
    ): Promise<T> => {
      try {
        const createRes = await chatCreateUsingPOST<ResponseType<T>>(params);
        const channel_id = createRes?.data?.channel_id || '';

        !props.channelId &&
          setChannel({
            channelName: createRes.data?.channel_name,
            channelId: channel_id,
            ...createRes.data,
          });
        return createRes.data;
      } catch (error: any) {
        const errMsg = error?.response?.data?.message || error?.data?.message || error?.message;
        throw new Error(errMsg);
      }
    },
    [props.channelId, setChannel],
  );

  const createOneChatParams = useCallback(async () => {
    // PPT创建，包含enhance ppt,one chat 内创建PPT
    if (currentInputMode === EChatType.chatWithPPT) {
      const pptSettingsParams = {
        advanceConfig: {
          ...refs?.pptControllerRef?.current?.getValues(),
          language: UPGRADE_DEFAULT_LANG,
        },
      };

      // enhance创建ppt
      if (props?.realTemplate?.mediaType === 'ppt' && file) {
        const page_count = fileExtraInfo?.page_count || 1;
        const enhancePPTParams = {
          md5: enhanceMd5,
          model: currentModel,
          prompt: value,
          message: value,
          template: props?.realTemplate,
          pptSettingsParams,
          filename: file?.name,
          extname: getFileType(file?.type, FileType.doc),
          fromPpt: true,
          isUploadToEnhance: true,
          language: UPGRADE_DEFAULT_LANG,
          pageCount: page_count,
          searchSwitch: props?.switchChecked,
        };
        return enhancePPTParams;
      }
      // one chat内创建ppt
      // 如果在对话里传advanceConfig，否则传pptSettingsParams
      const pptConfigValue = props?.channelId
        ? pptSettingsParams?.advanceConfig
        : { ...pptSettingsParams, model: currentModel };
      const pptConfigKey = props?.channelId ? 'advanceConfig' : 'pptSettingsParams';
      return {
        prompt: value,
        message: value,
        template: props?.realTemplate,
        [`${pptConfigKey}`]: pptConfigValue,
        channelType: undefined,
        searchSwitch: props?.switchChecked,
      };
    } else if (currentInputMode === EChatType.chatWithTPL && props?.realTemplate?.prompt) {
      // 通过模版创建对话
      return {
        model: props?.realTemplate?.model,
        message: props?.channelId ? value : fieldReplace(props?.realTemplate?.prompt, value),
        language: 'Auto',
        templateId: props?.realTemplate?.promptTemplateId,
        imageUrls,
        prompt: props?.channelId ? value : fieldReplace(props?.realTemplate?.prompt, value),
        searchSwitch: props?.switchChecked,
      };
    } else {
      // 文档one chat
      if (allUploadFiles.length > 0) {
        const docs = allUploadFiles
          .filter((i) => !!i.md5)
          .map((i) => ({
            md5: i?.md5,
            fileName: i.name,
            fileContentType: i?.type,
            uploadTime: i?.uploadTime || 1,
          }));
        const gdrives = allUploadFiles
          .filter((i) => !!i.mimeType)
          .map((i) => ({
            id: i.uid,
            mimeType: i?.mimeType as string,
            name: i.name,
            url: i?.url as string,
          }));
        const res = await createOneChatByFiles<OneChatFileResponse>({
          docs,
          extract_img_5k: true,
          model: currentModel,
          channelType: 'ONE_CHAT',
          message: value,
          language: UPGRADE_DEFAULT_LANG,
          languages: sendOcrLanguage.ocrValue,
          channelId: props?.channelId ?? undefined,
          searchSwitch: false,
          ...(gdrives?.length > 0 && { gdrives }),
        });
        // 有文档
        return {
          channelId: props?.channelId ?? res?.channel_id,
          roleEnum: 'SUMMARYPROMPT',
          message: value,
          prompt: value,
          docs: [
            ...docs.map((i) => ({
              file_name: i.fileName,
              file_type: i.fileContentType,
              file_page_count: 1,
              md5: i.md5,
            })),
            ...gdrives.map((i) => ({
              file_name: i.name,
              file_type: i.mimeType,
              file_page_count: 1,
            })),
          ],
          channelType: 'ONE_CHAT',
          searchSwitch: false,
        };
      } else {
        // 图片 one chat
        if (imageUrls.length > 0) {
          const message = props?.channelId ? value : value || DEFAULT_IMAGE_MESSAGE;
          return {
            fileType: 4,
            message,
            model: currentModel,
            imageUrls,
            imageFileNames,
            language: UPGRADE_DEFAULT_LANG,
            prompt: value,
            channelType: 'ONE_CHAT',
            searchSwitch: props?.switchChecked,
          };
        }
        return {
          prompt: value,
          message: value,
          model: currentModel,
          template: props?.realTemplate,
          channelType: 'ONE_CHAT',
          language: UPGRADE_DEFAULT_LANG,
          searchSwitch: props?.switchChecked,
        };
      }
    }
  }, [
    allUploadFiles,
    createOneChatByFiles,
    currentInputMode,
    currentModel,
    enhanceMd5,
    file,
    fileExtraInfo?.page_count,
    imageFileNames,
    imageUrls,
    props?.channelId,
    props?.realTemplate,
    props?.switchChecked,
    refs?.pptControllerRef,
    sendOcrLanguage.ocrValue,
    value,
  ]);

  const handleSend = useCallback(async () => {
    if (!canSend) return false;
    preparePropertyFor('Chat_EnterChat', 'forward_source', 'new_chat_send_enter');
    setSendLoading(true);
    try {
      if (props?.chatType === EChatType.writing) {
        await handleWritingDoc();
      } else {
        let sendParams: SendParams = {
          channelId: props?.channelId,
        };
        // 在one chat里
        const oneChatParams = await createOneChatParams();
        sendParams = {
          ...sendParams,
          ...oneChatParams,
        };
        await props?.onSend?.(sendParams);
        handleClearSendController();
        trackSend();
      }
    } catch (error: any) {
      setSendLoading(false);
      message.error(error?.response?.data?.message ?? error?.message ?? 'network error');
    } finally {
      setSendLoading(false);
    }
  }, [
    canSend,
    createOneChatParams,
    handleClearSendController,
    handleWritingDoc,
    preparePropertyFor,
    props,
    trackSend,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSendThrottle = useCallback(throttle(handleSend, 600), [handleSend]);

  const handlePressEnter = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      if (event.key === 'Enter' && event.shiftKey) {
        const textarea = event?.currentTarget;
        const selectionStart = textarea?.selectionStart;
        const selectionEnd = textarea?.selectionEnd;
        const newValue = `${textarea?.value?.slice(
          0,
          selectionStart,
        )}${'\n'}${textarea?.value?.slice(selectionEnd)}`;
        const newPos = selectionStart + 1;
        setValue(newValue);
        Promise.resolve().then(() => {
          textarea.setSelectionRange(newPos, newPos);

          if (newPos === newValue?.length) {
            textarea.scrollTo({
              top: textarea?.scrollHeight,
            });
          }
        });
      } else if (event.key === 'Enter' && canSend && !isComposition && !sendLoading) {
        handleSendThrottle();
      }
    },
    [canSend, handleSendThrottle, isComposition, sendLoading, setValue],
  );

  useEffect(() => {
    props?.channelId && handleClearSendController();
    setLastPPTTemplateId(0);
    return () => {
      handleClearSendController();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, props?.channelId]);

  return {
    value,
    inputActive,
    maximum,
    inputExceeded,
    computedPlaceholder,
    canSend,
    sendLoading,
    maxCount: MAX_COUNT,
    setValue,
    setInputActive,
    setIsComposition,
    handlePressEnter,
    handleInputChange,
    handlePaste,
    handleSendThrottle,
  };
};

export default useSendInput;
